<template>
  <Fragment>
    <header class="task__header">
      <div class="task__title">
        <VChip
          label
          small
          class="task__index"
        >
          {{ index + 1 }}
        </VChip>

        <div
          v-if="question.question"
          :class="['rte-content', 'bvi-speech', {'required': isRequired}]"
          v-html="question.question"
        />
        <VAlert
          v-else
          dense
        >
          {{ $t('$app.no_question_text') }}
          <RouterLink :to="{ name: 'messenger' }">
            {{ $t('$app.need_help') }}
          </RouterLink>
        </VAlert>
      </div>
    </header>

    <VCardText v-if="question.comments">
      <UComment>{{ question.comments }}</UComment>
    </VCardText>

    <VCardText class="text--primary">
      <TrainingQuizQuestionTypeGrading
        v-if="isGradingType"
        :value="userAnswers[0].answer"
        :type="question.subtype.code"
        :is-readonly="survey.is_completed"
        @input="evt => onChange(evt)"
      />

      <template v-else-if="isTextType">
        <p>{{ $t('$app.your_answer') }}:</p>
        <MarkdownEditor
          v-if="!survey.is_completed"
          @input="evt => onChange([evt])"
        />
        <MarkdownContent
          v-else-if="userAnswers[0]?.answer"
          :content="userAnswers[0]?.answer"
        />
        <p v-else>
          {{ $t('$app.no_answer') }}
        </p>
      </template>

      <Component
        :is="`training-quiz-question-type-${type}`"
        v-else-if="question?.answers?.length"
        :value="value"
        :answers="answers"
        :is-multiple-choice="question.multiple_choice"
        :is-readonly="survey.is_completed"
        @input="onChange"
      />

      <VAlert
        v-else
        dense
      >
        {{ $t('$app.no_answer_option') }}
        <RouterLink :to="{ name: 'messenger' }">
          {{ $t('$app.need_help') }}
        </RouterLink>
      </VAlert>
    </VCardText>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'

import MarkdownContent from '@components/MarkdownContent.vue'
import MarkdownEditor from '@components/MarkdownEditor.vue'
import TrainingQuizQuestionTypeGrading from '@components/TrainingQuizQuestionTypeGrading.vue'
import TrainingQuizQuestionTypeMapping from '@components/TrainingQuizQuestionTypeMapping.vue'
import TrainingQuizQuestionTypeRanking from '@components/TrainingQuizQuestionTypeRanking.vue'
import TrainingQuizQuestionTypeSimple from '@components/TrainingQuizQuestionTypeSimple.vue'
import UComment from '@components/UComment.vue'

import { Answer, AnswerSettings, QuestionAnswer } from '@/utils/quiz'

export default {
  name: 'TheSurveyQuestion',

  components: {
    Fragment,
    TrainingQuizQuestionTypeSimple,
    TrainingQuizQuestionTypeRanking,
    TrainingQuizQuestionTypeMapping,
    TrainingQuizQuestionTypeGrading,
    MarkdownEditor,
    MarkdownContent,
    UComment
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    survey: {
      type: Object,
      default: () => {}
    },

    question: {
      type: Object,
      required: true
    },

    userAnswers: {
      type: Array,
      default: () => []
    },

    index: {
      type: Number,
      default: 0
    },
  },

  data () {
    return {
      AnswerSettings
    }
  },

  computed: {
    type () {
      return this.question.type?.code || AnswerSettings.DEFAULT_QUESTION_TYPE
    },

    isRankingType () {
      return this.type === AnswerSettings.QUESTION_TYPE_RANKING
    },

    isTextType () {
      return this.type === AnswerSettings.QUESTION_TYPE_TEXT
    },

    isGradingType () {
      return this.type === AnswerSettings.QUESTION_TYPE_GRADING
    },

    isRequired () {
      return this.question.is_required
    },

    formattedUserAnswers () {
      return this.userAnswers.map(answer => new Answer(answer))
    },

    answers () {
      let answers = this.question.answers
      if (this.isRankingType && this.userAnswers.length) {
        answers = this.userAnswers || answers
      }

      return (answers || []).map(answer => new QuestionAnswer(answer, this.userAnswers))
    },
  },

  created () {
    this.register()
  },

  methods: {
    onChange (evt) {
      this.$emit('input', evt)
    },

    register () {
      let answers = this.formattedUserAnswers
      if (this.isRankingType) {
        answers = this.answers
      }

      this.$emit('input', (answers || []).map(answer => answer.id))
    }
  }
}
</script>

<style lang="scss">
.task {
  color: map-deep-get($material-light, "text", "primary");
}

.task:not(:last-child) {
  margin-bottom: 1rem;
}

.task__header {
  display: flex;
  flex-direction: column;
  background-color: rgba(black, 0.04);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: row;
  }
}

.task__index {
  margin-right: 0.5rem;
  flex: 0 0 auto;
}

.task__title {
  padding: 0.5rem 1rem;
  display: flex;
  flex: 1 1 auto;
}

.task .task__answers {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
